import $axios from '@/utils/request'

// 新增知识点
export function saveKnowledgePoint (data) {
  const url = '/knowledgeponit/saveKnowledgePoint'
  return $axios.fPost(url, data)
}

// 知识点列表
export function listKnowledgePoint (data) {
  const url = '/knowledgeponit/listKnowledgePoint'
  return $axios.fPost(url, data)
}
