import $axios from '@/utils/request'

// 学员登记接口
export function addGuest(data) {
    const url = '/user/addGuest'
    return $axios.fPost(url, data)
}

// 修改登记信息
export function editGuest(data) {
    const url = '/user/editGuest'
    return $axios.fPost(url, data)
}

// 访客学员列表
export function listGuest(data) {
    const url = '/user/listGuest'
    return $axios.fPost(url, data)
}

// 学员档案列表
export function listStudent(data) {
    const url = '/user/listStudent'
    return $axios.fPost(url, data)
}
// 学员档案列表（限定校区）
export function listStudentForCampus(data) {
    const url = '/user/listStudentForCampus'
    return $axios.fPost(url, data)
}

// 按教务统计沟通情况
export function getStudentMsgStaticByEdu(data) {
    const url = '/stu/msg/getStudentMsgStaticByEdu'
    return $axios.fPost(url, data)
}

// 访客学员转正
export function guest2user(data) {
    const url = '/user/guest2user'
    return $axios.fGet(url, data)
}

// 获取用户收费课程
export function getProductUserList(data) {
    const url = '/product/user/getProductUserList'
    return $axios.fPost(url, data)
}

// 获取用户收费课程明细
export function getProductUserInf(data) {
    const url = '/product/user/getProductUserInf'
    return $axios.fGet(url, data)
}

// 获取学生电子钱包金额
export function queryWallet(data) {
    const url = '/wallet/queryWallet'
    return $axios.fPost(url, data)
}

// 创建订单
export function subscribe(data) {
    const url = '/order/subscribe'
    return $axios.fPost(url, data)
}

// 获取订单列表
export function getOrderList(data) {
    const url = '/order/getOrderList'
    return $axios.fPost(url, data)
}

// 获取订单详情
export function getOrderItem(data) {
    const url = '/order/getOrderItem'
    return $axios.fGet(url, data)
}

// 取消订单
export function cancelOrderItem(data) {
    const url = '/order/cancel'
    return $axios.fPost(url, data)
}

// 获取课程包包含的课程列表
export function getProductListByGroups(data) {
    const url = '/product/group/getProductListByGroups'
    return $axios.fGet(url, data)
}

// 获取可用代金券
export function getUserCoupon(data) {
    const url = '/coupon/getUserCoupon'
    return $axios.fPost(url, data)
}

// 获取可用代金券
export function getProductByCoupon(data) {
    const url = '/coupon/getProductByCoupon'
    return $axios.fPost(url, data)
}

// 线下支付
export function unOnlineCashpay(data) {
    const url = '/cash/cashpay'
    return $axios.fPost(url, data)
}

// 支付宝支付
export function alipaySign(data) {
    const url = '/ali/pcpay'
    return $axios.fPost(url, data)
}

// 根据订单号查询使用的抵扣券
export function getCouponLogByOrder(data) {
    const url = '/coupon/getCouponLogByOrder'
    return $axios.fGet(url, data)
}

//获取可查看校区的资金池
export function getMyCampus(data) {
    const url = '/campus/getMyCampus'
    return $axios.fGet(url, data)
}

//获取可查看校区资金池明细
export function getDiscountLog(data) {
    const url = '/campus/getDiscountLog'
    return $axios.fPost(url, data)
}

// 校区优惠资金池充值
export function CampusCharge(data) {
    const url = '/campus/edit'
    return $axios.fPost(url, data)
}

//结转剩余课时的计算
export function calculatePrdocut(data) {
    const url = '/product/user/calculatePrdocut'
    return $axios.fPost(url, data)
}
//结转订单
export function carryover(data) {
    const url = '/order/carryover'
    return $axios.fPost(url, data)
}
//退费订单
export function refund(data) {
    const url = '/order/refund'
    return $axios.fPost(url, data)
}

//全部订单查询
export function getOrderByList(data) {
    const url = '/order/getOrderByList'
    return $axios.fPost(url, data)
}

//查询可操作校区的订单
export function getOrderByListLimitCampus(data) {
    const url = '/order/getOrderByListLimitCampus'
    return $axios.fPost(url, data)
}

//订单审核
export function refundVerify(data) {
    const url = '/order/refundVerify'
    return $axios.fPost(url, data)
}

//作废订单
export function invalid(data) {
    const url = '/order/invalid'
    return $axios.fGet(url, data)
}

//电子钱包明细
export function queryWalletInfo(data) {
    const url = '/wallet/queryWalletInfo'
    return $axios.fPost(url, data)
}

//匹配优惠方案校验
export function checkSub(data) {
    const url = '/order/checkSub'
    return $axios.fPost(url, data)
}

//获取授权校区的课程
export function getProductPriceLimitCampus(data) {
    const url = '/product/price/getProductPriceLimitCampus'
    return $axios.fPost(url, data)
}

//获取销售角色
export function getOrderRole(data) {
    const url = '/order/getOrderRole'
    return $axios.fPost(url, data)
}

//获取全员列表
export function listAllEmp(data) {
    const url = '/user/listAllEmp'
    return $axios.fGet(url, data)
}


//获取订单合计金额
export function getOrderMoneyToal(data) {
    const url = '/order/getOrderMoneyToal'
    return $axios.fPost(url, data)
}

//学员档案班级列表
export function listClass(data) {
    const url = '/stu/listClass'
    return $axios.fPost(url, data)
}

//学员档案历史班级列表
export function listHistoryClass(data) {
    const url = '/stu/listHistoryClass'
    return $axios.fPost(url, data)
}


//学员我的学员列表
export function listStudentLimitEdu(data) {
    const url = '/user/listStudentLimitEdu'
    return $axios.fPost(url, data)
}

//学员档案排课列表
export function listLesson(data) {
    const url = '/stu/listLesson'
    return $axios.fPost(url, data)
}

//学员档案课程列表
export function listCourse(data) {
    const url = '/stu/listCourse'
    return $axios.fPost(url, data)
}

//学员档案新增学员
export function addStudent(data) {
    const url = '/merchant/user/addStudent'
    return $axios.fPost(url, data)
}

//学员档案编辑学员
export function editStudent(data) {
    const url = '/user/editStudent'
    return $axios.fPost(url, data)
}

//学员档案新增沟通记录
export function addMsg(data) {
    const url = '/stu/msg/addMsg'
    return $axios.fPost(url, data)
}

//学员档案新增沟通记录列表
export function listMsg(data) {
    const url = '/stu/msg/listMsg'
    return $axios.fPost(url, data)
}

// 编辑沟通记录
export function editMsg(data) {
    const url = '/stu/msg/editMsg'
    return $axios.fPost(url, data)
}

// 删除沟通记录
export function deleteMsg(data) {
    const url = '/stu/msg/deleteMsg'
    return $axios.fGet(url, data)
}

//学员档案批量增加学员
export function addBatchStudent(data) {
    const url = '/user/addBatchStudent'
    return $axios.fPost(url, data)
}

// 学生列表 - 校区
export function getListStudentForCampus(data) {
    const url = '/user/listStudentForCampus'
    return $axios.fPost(url, data)
}

// 新的退费结转订单展示列表
export function getProductByPayOrder(data) {
    const url = '/product/user/getProductByPayOrder'
    return $axios.fPost(url, data)
}

// 根据教务id搜索学员
export function listStudentByLimitEdu(data) {
    const url = '/user/listStudentByLimitEdu'
    return $axios.fPost(url, data)
}

// 根据教务id批量操作学员(新增，删除)
export function batchEdu(data) {
    const url = '/merchant/user/batchEdu'
    return $axios.fPost(url, data)
}

// 可操作校区学生列表
export function listByLimitEmployCampus(data) {
    const url = '/merchant/user/listByLimitEmployCampus'
    return $axios.fPost(url, data)
}


// 获取订单项(根据课程和订单)
export function queryItemByOrderProduct(data) {
    const url = '/merchant/order/queryItemByOrderProduct'
    return $axios.fGet(url, data)
}

// 获取片区教师
export function listMemberMy(data) {
    const url = '/merchant/cta/listMemberMy'
    return $axios.fPost(url, data)
}

// 获取试听课表
export function listTryLesson(data) {
    const url = '/merchant/lesson/listTryLesson'
    return $axios.fPost(url, data)
}

// 获取试听统计
export function tryStat(data) {
    const url = '/merchant/lesson/tryStat'
    return $axios.fPost(url, data)
}

// 获取试听记录
export function listTryStuInfo(data) {
    const url = '/merchant/lesson/listTryStuInfo'
    return $axios.fPost(url, data)
}

// 修改试听课学员信息
export function editTryStu(data) {
    const url = '/merchant/lesson/editTryStu'
    return $axios.fGet(url, data)
}

// 给试听课增加学员
export function addStudentLesson(data) {
    const url = '/merchant/lesson/addStudent'
    return $axios.fPost(url, data)
}

// 新增直播间（试听）
export function addAudionBlive(data) {
    const url = '/merchant/audionLive/addAudionBlive'
    return $axios.fPost(url, data)
}

// 关联直播间
export function linkLive(data) {
    const url = '/merchant/lesson/linkLive'
    return $axios.fGet(url, data)
}