<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane v-if="allflag" label="全部报告" name="first">
      <alltemplate ref="alltemplate"></alltemplate>
    </el-tab-pane>
    <el-tab-pane v-if="campusflag" label="校区报告" name="second">
      <campustemplate ref="campustemplate"></campustemplate>
    </el-tab-pane>
    <el-tab-pane v-if="myflag" label="我的报告" name="third">
      <mytemplate ref="mytemplate"></mytemplate>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import alltemplate from '@/views/senate/studentTemplate'
  import campustemplate from '@/views/senate/studentTemplate'
  import mytemplate from '@/views/senate/studentTemplate'

  export default {
    name: "studentClass",
    data() {
      return {
        activeName: 'first',
        allflag:false,
        campusflag:false,
        myflag:false,
      };
    },
    components: {
      alltemplate,
      campustemplate,
      mytemplate
    },
    created() {

        let perms = localStorage.getItem('perms')
        if(perms.indexOf('template:all') != -1) {
          this.allflag = true
        }
        if(perms.indexOf('template:campus') != -1) {
          this.campusflag = true
        }
        if(perms.indexOf('template:my') != -1) {
          this.myflag = true
        }
      this.$nextTick(()=> {
        if(perms.indexOf('template:all') != -1) {
          this.$refs.alltemplate.getListDataSnapshot()
          this.$refs.alltemplate.setPageType('alltemplate')
        }else if(perms.indexOf('template:campus') != -1){
          this.$refs.campustemplate.listTemplateLimitCampus()
          this.$refs.campustemplate.setPageType('camputstemplate')
        }else if(perms.indexOf('template:my') != -1) {
          this.$refs.mytemplate.listTemplateLimitOper()
          this.$refs.mytemplate.setPageType('mytemplate')
        }
      })
    },
    methods:{
      handleClick(tab, event) {
        switch (tab.name) {
          case "first":
            this.$refs.alltemplate.getListDataSnapshot()
            break;
          case "second":
            this.$refs.campustemplate.listTemplateLimitCampus()
            break;
          case "third":
            this.$refs.mytemplate.listTemplateLimitOper()
            break;
        }

      }
    },
  };
</script>
