import $axios from '@/utils/request'

// 模块列表
export function listModel (data) {
  const url = '/datasnapshot/listModel'
  return $axios.fPost(url, data)
}

// 模板列表
export function listTemplate (data) {
  const url = '/datasnapshot/listTemplate'
  return $axios.fPost(url, data)
}

// 保存模板
export function saveTemplate (data) {
  const url = '/datasnapshot/saveTemplate'
  return $axios.fPost(url, data)
}

// 做题情况数据
export function getExerisesModelData (data) {
  const url = '/datasnapshot/getExerisesModelData'
  return $axios.fPost(url, data)
}

// 编辑模板
export function editTemplate (data) {
  const url = '/datasnapshot/editTemplate'
  return $axios.fPost(url, data)
}

// 课堂评价维度
export function getScopes (data) {
  const url = '/datasnapshot/getScopes'
  return $axios.fPost(url, data)
}

// 生成数据快照
export function snapshotData (data) {
  const url = '/datasnapshot/snapshotData'
  return $axios.fPost(url, data)
}

// 数据快照列表
export function getListDataSnapshot (data) {
  const url = '/datasnapshot/listDataSnapshot'
  return $axios.fPost(url, data)
}

// 校区数据快照列表
export function listTemplateLimitCampus (data) {
  const url = '/datasnapshot/listTemplateLimitCampus'
  return $axios.fPost(url, data)
}

// 我的数据快照列表
export function listTemplateLimitOper (data) {
  const url = '/datasnapshot/listTemplateLimitOper'
  return $axios.fPost(url, data)
}

// 更新快照
export function updateSnapshotData (data) {
  const url = '/datasnapshot/updateSnapshotData'
  return $axios.fPost(url, data)
}
