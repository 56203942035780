<template>
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="48">
          <el-col :md="6" :sm="24">
            <el-form-item label="模板名称：">
              <el-input
                v-model="templateName"
                placeholder="请输入模板名称"
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6">
            <el-form-item label="学生姓名：">
              <el-select
                filterable
                remote
                reserve-keyword
                placeholder="请输入学生姓名"
                :remote-method="remotestudentMethod"
                v-model="name">
                <el-option v-for="item in studentList" :key="item.userId" :label="item.name+'['+item.loginName+']'" :value="item.userId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-button type="primary" @click="search" size="small">查 询</el-button>
          <el-button type="primary" @click="reset" size="small">重 置</el-button>
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex">
        <span>报告列表</span>
        <div class="flex_1"></div>
        <el-button
          type="primary"
          size="mini"
          @click="opentemplate"
        >创建报告</el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="dataList"
          fit
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
             <template v-if="item.label === '学员姓名'">
               <span v-if="JSON.parse(scope.row.dataSnapshot).userModule">{{JSON.parse(scope.row.dataSnapshot).userModule.realName}}</span>
             </template>
              <template v-if="item.label === '创建时间'">
                <span>{{scope.row.createTime ? scope.row.createTime.substring(0, 19) : ''}}</span>
              </template>
              <template v-else-if="item.label === '操作'">
                <span class="el-dropdown-link" @click="previewReport(scope.row)">
                   查看
                </span>
                <el-divider direction="vertical"></el-divider>
                <span class="el-dropdown-link" @click="editReport(scope.row)">
                   编辑
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-tooltip class="item" effect="dark" content="点击复制报告链接" placement="top">
                   <span class="el-dropdown-link" @click="doCopy(scope.row)">
                     分享
                  </span>
                </el-tooltip>

              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        style="float: right;margin-right: 20px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>

    <add-report :show="templateDialog" :reportObject="reportObject" @close="templateDialog = false;reportObject=null" @updateView="search"></add-report>
  </div>
</template>

<script>
  import { getListDataSnapshot,listTemplateLimitCampus,listTemplateLimitOper } from '@/api/senate/report'
  import datePicker from '@/components/date-picker'
  import addReport from '@/components/senate/add-report'
  import TextButton from '@/components/TextButton'
  import { getStuList } from '@/api/system/student'

  // 默认展示列
  const baseColSetting = [
    {
      label: '学员姓名',
      prop: 'name',
      state: true,
      align: 'center',
      width: '120'
    },
    {
      label: '模板名称',
      prop: 'dataTemplateName',
      state: true,
      align: 'center',
      width: '120'
    },
    {
      label: '创建时间',
      prop: 'createTime',
      state: true,
      sortable: true,
      align: 'center',
      width: '150'
    },
    {
      label: '创建者',
      prop: 'creatorName',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '操作',
      state: true,
      align: 'center',
      width: '100'
    },
  ]
  export default {
    name:'reportTemplate',
    components: {datePicker,TextButton,addReport},
    computed: {
      defaultHeader() {
        return this.colSetting.filter(item => item.state)
      },
    },

    data() {
      return {
        templateName:'',
        name:'',
        studentList:[],
        dataList: [],
        pageType:'',
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        templateDialog:false,
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        reportObject: null,
      }
    },
    methods: {
      setPageType(type) {
        this.pageType = type
      },
      remotestudentMethod(query) {
        this.staffList = []
        if (query.trim() !== '') {
          this.getStuList(query)
        }
      },
      async getStuList (query) {
        const res = await getStuList({
          pageNum: 1,
          pageSize: 10000,
          name:query,
        })
        this.studentList = res.body.list
      },
      doCopy(row) {
        this.$copyText(process.env.VUE_APP_REPORT_ADDRESS + row.dataSnapshotId).then(function (e) {
         window.$msg('分享链接已成功复制到剪切板')
        }, function (e) {
          window.$msg('复制异常',2)
        })

      },
      previewReport(row){
        window.open(process.env.VUE_APP_REPORT_ADDRESS + row.dataSnapshotId)
      },
      editReport(row){
        this.reportObject = row
        this.templateDialog = true
      },
      opentemplate() {
        this.templateDialog = true
      },
      search(){
        this.pagination.currentPage = 1
        this.queryData()
      },

      reset() {
        this.name = ''
        this.templateName = ''
      },

      // 我的报告列表
      async listTemplateLimitOper () {
        this.pageType = 'mytemplate'
        this.pagination.currentPage = 1
        this.pagination.pageSize = 10
        const res = await listTemplateLimitOper({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          dataTempalteName:this.templateName,
          userId:this.name,
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },
      // 校区报告列表
      async listTemplateLimitCampus () {
        this.pageType = 'camputstemplate'
        this.pagination.currentPage = 1
        this.pagination.pageSize = 10
        const res = await listTemplateLimitCampus({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          dataTempalteName:this.templateName,
          userId:this.name,
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },
      // 获取学员报告列表
      async getListDataSnapshot () {
        this.pageType = 'alltemplate'
        this.pagination.currentPage = 1
        this.pagination.pageSize = 10
        const res = await getListDataSnapshot({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          dataTempalteName:this.templateName,
          userId:this.name,
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },
      // 分页
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.queryData()
      },
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.queryData()
      },
      queryData() {
        if(this.pageType === 'alltemplate') {
          this.getListDataSnapshot()
        }else if(this.pageType === 'camputstemplate'){
          this.listTemplateLimitCampus()
        }else if(this.pageType === 'mytemplate'){
          this. listTemplateLimitOper()
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../style/container.scss";
</style>
