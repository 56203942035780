var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.titleText) } }),
        ]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-row",
            [
              _c("span", [_vm._v("选择报告模板：")]),
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.editReport,
                    "collapse-tags": "",
                    placeholder: "请选择报告模板",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleSelectChange()
                    },
                  },
                  model: {
                    value: _vm.templateId,
                    callback: function ($$v) {
                      _vm.templateId = $$v
                    },
                    expression: "templateId",
                  },
                },
                _vm._l(_vm.templateOptions, function (item) {
                  return _c("el-option", {
                    key: item.dataTemplateId,
                    attrs: {
                      label: item.dataTemplateName,
                      value: item.dataTemplateId,
                    },
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _vm._v("学生姓名: "),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        disabled: _vm.editReport,
                        "reserve-keyword": "",
                        placeholder: "请输入搜索内容",
                        "remote-method": _vm.remotestudentMethod,
                      },
                      on: { change: _vm.handleRemote },
                      model: {
                        value: _vm.reportStudent,
                        callback: function ($$v) {
                          _vm.reportStudent = $$v
                        },
                        expression: "reportStudent",
                      },
                    },
                    _vm._l(_vm.studentList, function (item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: {
                          label: item.name + "[" + item.loginName + "]",
                          value: item.userId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showTemplateDetail && _vm.showTemplateName
            ? _c(
                "draggable",
                {
                  attrs: { disabled: "true", options: { animation: 200 } },
                  model: {
                    value: _vm.moduleOptions,
                    callback: function ($$v) {
                      _vm.moduleOptions = $$v
                    },
                    expression: "moduleOptions",
                  },
                },
                _vm._l(_vm.moduleOptions, function (item, index) {
                  return _c(
                    "el-row",
                    { key: index },
                    [
                      item.dataModelName === "客观评价"
                        ? _c(
                            "el-row",
                            [
                              _c("el-row", { staticClass: "itemtop" }, [
                                _vm._v(" 课堂表现: "),
                              ]),
                              _c("hr"),
                              _c(
                                "el-row",
                                _vm._l(
                                  _vm.courseComment,
                                  function (childrenitem, childrenindex) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: childrenindex,
                                        staticStyle: {
                                          display: "flex",
                                          "margin-top": "15px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "16px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(childrenitem.dimension)
                                            ),
                                          ]
                                        ),
                                        _c("el-rate", {
                                          staticStyle: {
                                            "margin-left": "40px",
                                            "font-size": "16px",
                                          },
                                          model: {
                                            value: childrenitem.score,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                childrenitem,
                                                "score",
                                                $$v
                                              )
                                            },
                                            expression: "childrenitem.score",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : item.dataModelName === "知识点得分"
                        ? _c(
                            "el-row",
                            [
                              _c("el-row", { staticClass: "itemtop" }, [
                                _vm._v(" 知识点: "),
                              ]),
                              _c("hr"),
                              _vm._v(" 选择知识点： "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    "collapse-tags": "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.handleCourse },
                                  model: {
                                    value: _vm.knowledgeid,
                                    callback: function ($$v) {
                                      _vm.knowledgeid = $$v
                                    },
                                    expression: "knowledgeid",
                                  },
                                },
                                _vm._l(_vm.programOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.title,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 12 } },
                                _vm._l(
                                  _vm.knowledgeOptions,
                                  function (item, index) {
                                    return _c(
                                      "el-col",
                                      { key: index, attrs: { span: 6 } },
                                      [
                                        _c("el-col", [
                                          _vm._v(_vm._s(item.knowledgeContent)),
                                        ]),
                                        _c("el-input", {
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInput(
                                                item.score,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.score,
                                            callback: function ($$v) {
                                              _vm.$set(item, "score", $$v)
                                            },
                                            expression: "item.score",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : item.dataModelName === "编程题统计"
                        ? _c(
                            "el-row",
                            [
                              _c("el-row", { staticClass: "itemtop" }, [
                                _vm._v(" 做题情况: "),
                              ]),
                              _c("hr"),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "collapse-tags": "",
                                        placeholder: "请选择课程",
                                      },
                                      on: { change: _vm.programChange },
                                      model: {
                                        value: _vm.courseProgramId,
                                        callback: function ($$v) {
                                          _vm.courseProgramId = $$v
                                        },
                                        expression: "courseProgramId",
                                      },
                                    },
                                    _vm._l(_vm.programOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.title,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      "总题目数量：" +
                                        _vm._s(_vm.programObject.totalNum)
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      "完成数量：" +
                                        _vm._s(_vm.programObject.completeNum)
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      "提交数量：" +
                                        _vm._s(_vm.programObject.sumitNum)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : item.dataModelName === "综合评价"
                        ? _c(
                            "el-row",
                            [
                              _c("el-row", { staticClass: "itemtop" }, [
                                _vm._v(" 综合评价: "),
                              ]),
                              _c("hr"),
                              _c(
                                "el-radio-group",
                                {
                                  staticStyle: {
                                    "margin-top": "15px",
                                    "margin-bottom": "15px",
                                  },
                                  model: {
                                    value: _vm.radio,
                                    callback: function ($$v) {
                                      _vm.radio = $$v
                                    },
                                    expression: "radio",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "优秀" } }),
                                  _c("el-radio", { attrs: { label: "良好" } }),
                                  _c("el-radio", { attrs: { label: "及格" } }),
                                  _c("el-radio", { attrs: { label: "一般" } }),
                                ],
                                1
                              ),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入评价内容",
                                },
                                model: {
                                  value: _vm.feedback,
                                  callback: function ($$v) {
                                    _vm.feedback = $$v
                                  },
                                  expression: "feedback",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "text-left", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", {
            attrs: { type: "primary", size: "small" },
            domProps: {
              textContent: _vm._s(
                _vm.reportObject === null ? "生成报告" : "保存报告"
              ),
            },
            on: { click: _vm.confirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }