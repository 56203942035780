import $axios from '@/utils/request'

// 获取学员列表
export function getStuList (data) {
  const url = '/user/listStudent'
  return $axios.fPost(url, data)
}

// 新增学员
export function addStu (data) {
  const url = '/user/addStudent'
  return $axios.fPost(url, data)
}

// 编辑学员
export function editStu (data) {
  const url = '/user/editStudent'
  return $axios.fPost(url, data)
}
