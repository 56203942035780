var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-form", { attrs: { inline: true } }, [
        _c(
          "div",
          { staticClass: "row-bg" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 48 } },
              [
                _c(
                  "el-col",
                  { attrs: { md: 6, sm: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "模板名称：" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: { placeholder: "请输入模板名称" },
                          model: {
                            value: _vm.templateName,
                            callback: function ($$v) {
                              _vm.templateName = $$v
                            },
                            expression: "templateName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "学生姓名：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "请输入学生姓名",
                              "remote-method": _vm.remotestudentMethod,
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          },
                          _vm._l(_vm.studentList, function (item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: {
                                label: item.name + "[" + item.loginName + "]",
                                value: item.userId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.search },
                  },
                  [_vm._v("查 询")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.reset },
                  },
                  [_vm._v("重 置")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            { staticClass: "flex", attrs: { slot: "header" }, slot: "header" },
            [
              _c("span", [_vm._v("报告列表")]),
              _c("div", { staticClass: "flex_1" }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.opentemplate },
                },
                [_vm._v("创建报告")]
              ),
              _c("text-button", {
                attrs: { icon: "el-icon-refresh", contentTit: "刷新" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.search.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-bar",
            { attrs: { fixed: "", static: true } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.dataList, fit: "" } },
                _vm._l(_vm.defaultHeader, function (item) {
                  return _c("el-table-column", {
                    key: item.label,
                    attrs: {
                      label: item.label,
                      align: item.align ? item.align : "left",
                      "min-width": item.width ? item.width : "auto",
                      sortable: item.sortable ? item.sortable : false,
                      prop: item.prop,
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.label === "学员姓名"
                                ? [
                                    JSON.parse(scope.row.dataSnapshot)
                                      .userModule
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              JSON.parse(scope.row.dataSnapshot)
                                                .userModule.realName
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              item.label === "创建时间"
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.createTime
                                            ? scope.row.createTime.substring(
                                                0,
                                                19
                                              )
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                : item.label === "操作"
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-dropdown-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewReport(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 查看 ")]
                                    ),
                                    _c("el-divider", {
                                      attrs: { direction: "vertical" },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-dropdown-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editReport(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    ),
                                    _c("el-divider", {
                                      attrs: { direction: "vertical" },
                                    }),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "点击复制报告链接",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "el-dropdown-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.doCopy(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v(" 分享 ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _c("span", [
                                    _vm._v(_vm._s(scope.row[item.prop])),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { float: "right", "margin-right": "20px" },
            attrs: {
              background: "",
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("add-report", {
        attrs: { show: _vm.templateDialog, reportObject: _vm.reportObject },
        on: {
          close: function ($event) {
            _vm.templateDialog = false
            _vm.reportObject = null
          },
          updateView: _vm.search,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }