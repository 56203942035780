<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="titleText"></span>
    </div>
    <!-- reportObject：{{reportObject}} -->
    <!-- <hr> -->
    <!-- templateOptions：{{templateOptions}} -->
    <el-row >
      <el-row>

      <span>选择报告模板：</span>
        <el-select v-model="templateId"  :disabled="editReport"   collapse-tags @change="handleSelectChange()" placeholder="请选择报告模板">
          <el-option
            v-for="(item) in templateOptions"
            :key="item.dataTemplateId"
            :label="item.dataTemplateName"
            :value="item.dataTemplateId">
          </el-option>
        </el-select>
        <div style="margin-top: 20px;">学生姓名:
          <el-select
            filterable
            remote
            :disabled="editReport"
            reserve-keyword
            placeholder="请输入搜索内容"
            :remote-method="remotestudentMethod"
            @change="handleRemote"
            v-model="reportStudent">
            <el-option v-for="item in studentList" :key="item.userId" :label="item.name+'['+item.loginName+']'" :value="item.userId"></el-option>
          </el-select>
        </div>
      </el-row>
      <draggable v-model="moduleOptions" disabled="true" v-if="showTemplateDetail && showTemplateName"  :options="{animation:200}">
        <el-row v-for="(item,index) in moduleOptions" :key="index">
          <el-row v-if="item.dataModelName === '客观评价'">
            <el-row class="itemtop">
              课堂表现:
            </el-row>
            <hr/>
            <el-row >
              <el-col style="display: flex;margin-top: 15px;"  v-for="(childrenitem,childrenindex) in courseComment"  :key="childrenindex">
                <span style="font-size: 16px;">{{childrenitem.dimension}}</span>
                <el-rate style="margin-left: 40px;font-size: 16px;" v-model="childrenitem.score"></el-rate>
              </el-col>
            </el-row>
          </el-row>
          <el-row v-else-if="item.dataModelName === '知识点得分'">
            <el-row  class="itemtop">
              知识点:
            </el-row>
            <hr/>
            选择知识点：
            <el-select v-model="knowledgeid" collapse-tags placeholder="请选择" @change="handleCourse">
              <el-option
                v-for="item in programOptions"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
            <el-row :gutter="12">
              <el-col v-for="(item,index) in knowledgeOptions" :span="6" :key="index">
                <el-col>{{item.knowledgeContent}}</el-col>
                <el-input v-model="item.score" @input="limitInput(item.score, index)"></el-input>
              </el-col>
            </el-row>
          </el-row>
          <el-row v-else-if="item.dataModelName === '编程题统计'">
            <el-row  class="itemtop">
              做题情况:
            </el-row>
            <hr/>
            <el-row>
            <el-select v-model="courseProgramId" @change="programChange"  collapse-tags placeholder="请选择课程">
              <el-option
                v-for="item in programOptions"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
            </el-row>
            <el-row style="margin-top: 10px;">
              <el-col :span="4">总题目数量：{{programObject.totalNum}}</el-col>
              <el-col :span="4">完成数量：{{programObject.completeNum}}</el-col>
              <el-col :span="4">提交数量：{{programObject.sumitNum}}</el-col>
            </el-row>

          </el-row>
          <el-row v-else-if="item.dataModelName === '综合评价'">
            <el-row  class="itemtop">
              综合评价:
            </el-row>
            <hr/>
            <el-radio-group v-model="radio" style="margin-top: 15px;margin-bottom: 15px;">
              <el-radio label="优秀"></el-radio>
              <el-radio label="良好"></el-radio>
              <el-radio label="及格"></el-radio>
              <el-radio label="一般"></el-radio>
            </el-radio-group>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入评价内容"
              v-model="feedback">
            </el-input>

          </el-row>
        </el-row>
      </draggable>
    </el-row>

    <el-row slot="footer" class="text-left">
      <el-button type="primary" @click="confirm" size="small" v-text="reportObject === null ? '生成报告' : '保存报告'"></el-button>
    </el-row>

  </el-dialog>
</template>

<script>
  import draggable from 'vuedraggable'
  import { getStuList } from '@/api/system/student'
  import { listCourse } from '@/api/frontbusiness'
  import { listTemplate,listModel,getScopes,snapshotData,updateSnapshotData,getExerisesModelData} from '@/api/senate/report'
  import { listKnowledgePoint } from '@/api/research/knowledge'
  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
      reportObject: {
        default: () => {
          return null
        },
        type: Object
      }
    },

    components:{draggable},
    data () {
      return {
        reportStudent:'',
        creatorName: '',
        realName: '',
        staffList:[],
        studentList:[],
        courseProgramId:'',
        showTemplateDetail:false,// 显示模板具体的模块开关
        showTemplateName:false,// 显示模板具体的模块开关
        knowledgeid:'',
        editReport:false,
        knowledgeOptions:[],
        hasknowledgeMoudel:false,
        props: { multiple: true },
        programOptions: [],
        radio: null,
        feedback:'',
        courseComment: [],
        moduleOptions:[],
        templateId:'',
        templateOptions:[],
        titleText:'',
        seleModule: [],
        modelIds: '',
        campusArray: [], // 校区列表
        modelTypes:'',
        programObject:{},
      }
    },
    methods: {
      openDialog () {
        this.reportStudent = ''
        this.templateId = ''
        this.courseProgramId = ''
        this.staffList = []
        this.studentList = []
        this.knowledgeOptions = []
        this.programOptions = []
        this.courseComment = []
        this.moduleOptions = []
        this.templateOptions = []
        this.listTemplate()
        if (this.reportObject === null) {
          this.titleText = '创建报告'
          this.editReport = false
          this.showTemplateDetail = false
          this.showTemplateName = false
        } else {
          this.titleText = '编辑报告'
          this.editReport = true
          this.showTemplateDetail = true
          this.showTemplateName = true
          this.templateId = this.reportObject.dataTemplateId
          this.creatorName = JSON.parse(this.reportObject.dataSnapshot).userModule.creatorName
          this.remotestudentMethod(JSON.parse(this.reportObject.dataSnapshot).userModule.realName)
          this.realName = JSON.parse(this.reportObject.dataSnapshot).userModule.realName
          this.reportStudent = JSON.parse(this.reportObject.dataSnapshot).userModule.userId
          console.log(JSON.stringify(JSON.parse(this.reportObject.dataSnapshot)))
          this.listCourse(JSON.parse(this.reportObject.dataSnapshot).userModule.userId)
          this.courseProgramId = JSON.parse(this.reportObject.dataSnapshot).exercisesModel.courseId
          this.getExerisesModelData()
          this.knowledgeid = ""
          this.knowledgeOptions = JSON.parse(this.reportObject.dataSnapshot).knowledgePointModel
          this.radio = JSON.parse(this.reportObject.dataSnapshot).subjectiveCommentModel.subjectLevel
          this.feedback = JSON.parse(this.reportObject.dataSnapshot).subjectiveCommentModel.subjectContent
        }
      },

      remotestudentMethod(query) {
        this.staffList = []
        if (query.trim() !== '') {
          this.getStuList(query)
        }
      },
      programChange() {
        this.getExerisesModelData()
      },
      async getExerisesModelData() {
        const response = await getExerisesModelData({
          courseId:this.courseProgramId,
          userModule: {
            userId: this.reportStudent,
          },
        })
        if(response && response.state === 'success') {
          this.programObject = response.body
        }
      },
      async getScopes() {
        const res = await getScopes();
        let courseData = []
        if (this.reportObject === null) {
          res.body.tcomment.forEach(ele => {
            courseData.push({
              dimension: ele.scopeName,
              score: 0,
              maxScore: 5
            })
          })
          this.courseComment = courseData
        } else {
          this.courseComment = []
          JSON.parse(this.reportObject.dataSnapshot).objectiveCommentModel.forEach(ele => {
            this.courseComment.push({
              dimension: ele.dimension,
              score: Number(ele.score),
              maxScore: Number(ele.maxScore)
            })
          })
        }
      },
      async getStuList (query) {
        const res = await getStuList({
          pageNum: 1,
          pageSize: 10000,
          name:query,
        })
        this.studentList = res.body.list
      },

      limitInput (value, index) {
        if (Number(value) > 100) {
          return this.knowledgeOptions[index].score = ''
        } else {
          this.knowledgeOptions[index].score =
          ("" + value) // 第一步：转成字符串
            .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
            .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
            .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
            .match(/^\d*(\.?\d{0,1})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
        }
      },

      listModel(row) {
        listModel({
          modelIds:row.modelIds
        }).then(res => {
          if (res.state === 'success') {
            this.moduleOptions = res.body
            console.log(JSON.stringify(this.moduleOptions))
            let courseCommentFlag = false
            this.modelIds = ''
            this.modelTypes = ''
            this.moduleOptions.forEach((item, index)=> {
              if (item.dataModelName === '客观评价')  {
                courseCommentFlag = true
              }
              if(item.dataModelName === '知识点得分' ) {
                this.hasknowledgeMoudel = true
              }
              if (Number(index + 1) === Number(this.moduleOptions.length)) {
                this.modelIds += item.modelId
                this.modelTypes += item.type
              } else {
                this.modelIds += item.modelId+','
                this.modelTypes += item.type+','
              }
            })
            if(courseCommentFlag) {
              this.getScopes()
            }
            this.showTemplateDetail = true
          }
        })
      },
      handleSelectChange() {
        let currentObject = {}
        this.templateOptions.forEach((item)=> {
          if(this.templateId === item.dataTemplateId) {
            currentObject = item
          }
        })
        this.listModel(currentObject)
      },

      async handleCourse(val) {
        if(val) {
          const res = await listKnowledgePoint({
            pageNum: 1,
            pageSize: 10000,
            needCount: true,
            extId: val
          })
          let knowledgeData = []
          res.body.list.forEach(ele => {
            knowledgeData.push({
              knowledgeContent: ele.content,
              score: '',
              maxScore: 100
            })
          })
          this.knowledgeOptions = knowledgeData
        }
      },

      handleRemote(val) {
        if (val) {
          this.studentList.forEach(ele => {
            if (ele.userId === val) {
              this.realName = ele.name
            }
          })
          this.programObject = {}
          this.showTemplateName = true
          this.radio = null
          this.feedback = ''
          this.courseProgramId = ''
          this.knowledgeid = ''
          this.courseComment.forEach(item => {
            item.score =  0
          })
          this.listCourse(val)
        }
      },

      async listCourse(id) {
        const res = await listCourse({
          userId: id,
          pageNum: 1,
          pageSize: 10000,
        })
        this.programOptions = res.body.list
      },

      close () { this.$emit('close') },

      // 获取课程评价列表
      listTemplate () {
        listTemplate({
          pageNum: 1,
          pageSize: 10000,
          needCount: true,
        }).then(res => {
          if (res.state === 'success') {
            this.templateOptions = res.body.list
            if (this.reportObject !== null) {
              this.handleSelectChange()
            }
          }
        })
      },

      confirm () {
        if (this.reportObject === null) {
          console.log(this.hasknowledgeMoudel)
          console.log(this.knowledgeOptions.length)
          if(this.reportStudent === '') {
            window.$msg('请选择学生姓名',2)
            return false
          }else if (this.templateId === '') {
            window.$msg('请选择报告模板',2)
            return false
          }else if(this.hasknowledgeMoudel && this.knowledgeOptions.length === 0) {
            window.$msg('课程关联的知识点不能为空',2)
            return  false
          }

        //   snapshotData({
        //     dataTemplateId: this.templateId,
        //     modelIds: this.modelIds,
        //     modelTypes: this.modelTypes,
        //     userModule: {
        //       userId: this.reportStudent,
        //       realName: this.realName,
        //       creatorName: this.creatorName,
        //     },
        //     courseId: this.courseProgramId,
        //     knowledgePointModels: this.knowledgeOptions,
        //     objectiveCommentModels: this.courseComment,
        //     subjectiveCommentModel: {
        //       subjectContent: this.feedback,
        //       subjectLevel: this.radio
        //     }
        //   }).then(res => {
        //     if (res.state === 'success') {
        //       window.$msg('创建成功')
        //       this.close()
        //       this.$emit('updateView')
        //     }
        //   })
        // } else {
        //   // 编辑成功
        //   updateSnapshotData({
        //     dataSnapshotId: this.reportObject.dataSnapshotId,
        //     dataTemplateId: this.templateId,
        //     modelIds: this.modelIds,
        //     modelTypes: this.modelTypes,
        //     userModule: {
        //       userId: this.reportStudent,
        //       realName: this.realName,
        //       creatorName: this.creatorName,
        //     },
        //     courseId: this.courseProgramId,
        //     knowledgePointModels: this.knowledgeOptions,
        //     objectiveCommentModels: this.courseComment,
        //     subjectiveCommentModel: {
        //       subjectContent: this.feedback,
        //       subjectLevel: this.radio
        //     }
        //   }).then(res => {
        //     if (res.state === 'success') {
        //       window.$msg('编辑成功')
        //       this.close()
        //       this.$emit('updateView')
        //     }
        //   })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/style/dialog.scss";
  .itemtop {
    font-weight: bold;
    margin-top: 20px;
  }
</style>
