var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _vm.allflag
        ? _c(
            "el-tab-pane",
            { attrs: { label: "全部报告", name: "first" } },
            [_c("alltemplate", { ref: "alltemplate" })],
            1
          )
        : _vm._e(),
      _vm.campusflag
        ? _c(
            "el-tab-pane",
            { attrs: { label: "校区报告", name: "second" } },
            [_c("campustemplate", { ref: "campustemplate" })],
            1
          )
        : _vm._e(),
      _vm.myflag
        ? _c(
            "el-tab-pane",
            { attrs: { label: "我的报告", name: "third" } },
            [_c("mytemplate", { ref: "mytemplate" })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }